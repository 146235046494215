import FaceSaggingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/face-sagging/FaceSaggingTemplate';
import { FACE_SAGGING_LOCATION, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/FaceSagging/FaceSaggingTemplate'
};

const createStory = props => () => ({
  components: { FaceSaggingTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <face-sagging-template v-bind="$options.storyProps" />
  </div>`
});

export const Female = createStory({
  faceSaggingLocations: [FACE_SAGGING_LOCATION.NECK, FACE_SAGGING_LOCATION.EYELIDS],
  gender: GENDER.FEMALE
});
